<template>
    <div class="container">
        <h1 class="title">ライブ</h1>

        <div class="buttons">
            <b-button @click="reload">Reload</b-button>

            <b-dropdown aria-role="list">
                <template #trigger>
                    <b-button label="ダウンロード" type="is-success" icon-right="angle-down" />
                </template>
                <b-dropdown-item aria-role="listitem" key="download">
                    <b-icon icon="download"></b-icon>
                    <vue-excel-xlsx :data="live" :columns="columns" filename="ライブ" sheetname="ライブ">
                        ライブ
                    </vue-excel-xlsx>
                </b-dropdown-item>
            </b-dropdown>
        </div>

        <b-table
            :data="live"
            striped
            hoverable
            paginated
            per-page="50"
            narrowed
            detailed
            detail-key="Id"
            detail-transition="fade"
            @details-open="(row) => $buefy.toast.open(`Expanded ${row.Title}`)"
            show-detail-icon
            default-sort='ScheduledStartDateTime'
            defaultSortDirection='asc'
            sortIcon='arrow-up'
            sortIconSize='is-small'
        >
            <b-table-column field="id" label="ID" numeric v-slot="props">
                {{ props.row.Id }}
            </b-table-column>
            <b-table-column field="Title" label="タイトル" v-slot="props" searchable>
                <a :href="'https://www.e-casebook.com/live?v=' + props.row.UUID" target="_blank">{{
                    props.row.Title
                }}</a>
            </b-table-column>
            <b-table-column field="EventName" label="イベント" v-slot="props" searchable>
                <span v-if="props.row.EventName !== null">
                    {{ props.row.EventName }}
                </span>
            </b-table-column>
            <b-table-column
                field="ScheduledStartDateTime"
                label="開始予定"
                v-slot="props"
                searchable
                :custom-search="searchByDay('ScheduledStartDateTime')"
                sortable
            >
                <span class="tag is-light" v-if="props.row.ScheduledStartDateTime !== null">
                    {{ props.row.ScheduledStartDateTime | formatDate }}
                </span>
            </b-table-column>
            <b-table-column field="ScheduledEndDateTime" label="終了予定" v-slot="props">
                <span class="tag is-light" v-if="props.row.ScheduledEndDateTime !== null">
                    {{ props.row.ScheduledEndDateTime | formatDate }}
                </span>
            </b-table-column>
            <b-table-column
                field="ActualStartDateTime"
                label="開始"
                v-slot="props"
                searchable
                :custom-search="searchByDay('ActualStartDateTime')"
            >
                <span class="tag is-success" v-if="props.row.ActualStartDateTime !== null">
                    {{ props.row.ActualStartDateTime | formatDate }}
                </span>
            </b-table-column>
            <b-table-column field="ActualEndDateTime" label="終了" v-slot="props">
                <span class="tag is-success" v-if="props.row.ActualStartDateTime !== props.row.ActualEndDateTime">
                    {{ props.row.ActualEndDateTime | formatDate}}
                </span>
            </b-table-column>

            <template #detail="props">
                <div class="buttons">
                    <b-button
                        tag="router-link"
                        :to="{ name: 'reservation', params: { liveId: props.row.Id }}"
                        type="is-link"
                        target="_blank"
                    >
                        視聴予約
                    </b-button>
                    <b-button
                        tag="router-link"
                        :to="{ name: 'audienceSurvey', params: { liveId: props.row.Id } }"
                        type="is-link"
                        target="_blank"
                    >
                        事前/事後アンケート
                    </b-button>
                    <b-button
                        tag="router-link"
                        :to="{ name: 'streamingSurvey', params: { liveId: props.row.Id } }"
                        type="is-link"
                        target="_blank"
                    >
                        ライブ中アンケート
                    </b-button>
                    <b-button
                        tag="router-link"
                        :to="{ name: 'comment', params: { liveId: props.row.Id }}"
                        type="is-link"
                        target="_blank"
                    >
                        ライブコメント
                    </b-button>
                    <b-button
                        tag="router-link"
                        :to="{ name: 'audienceData', params: { liveId: props.row.Id }}"
                        type="is-link"
                        target="_blank"
                    >
                        視聴者データ
                    </b-button>
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>
export default {
    name: "Live",
    data: () => ({
        columns: [
            { field: "Id", label: "ID" },
            { field: "Title", label: "タイトル" },
            { field: "EventTitle", label: "イベント" },
            { field: "Description", label: "内容" },
            { field: "ScheduledStartDateTime", label: "開始予定" },
            { field: "ScheduledEndDateTime", label: "終了予定" },
            { field: "ActualStartDateTime", label: "開始" },
            { field: "ActuralEndDateTime", label: "終了" },
        ],
    }),
    created() {
        this.$store.dispatch("getLives");
    },
    computed: {
        live: function () {
            return this.$store.getters.lives;
        },
    },
    methods: {
        reload: function () {
            this.$store.dispatch("getLives");
        },
        searchByDay: function (field) {
            return (data, searchWord) => {
                if (data[field]) {
                    const val = data[field].replace('T', ' ').replace('Z', '');
                    return val.startsWith(searchWord);
                }
            };
        },
    },
};
</script>

<style>
.link {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 40px;
}
p.update-msg {
    color: #cc3333;
    margin-bottom: 15px;
}
</style>
