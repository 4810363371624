<template>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">Login</p>
            <button type="button" class="delete" @click="$emit('close')" />
        </header>
        <section class="modal-card-body">
            <form action="">
                <b-field label="User Name">
                    <b-input type="text" v-model="username" autocomplete />
                </b-field>
                <b-field label="Password" v-if="!newPasswordRequired">
                    <b-input type="password" password-reveal v-model="password" autocomplete />
                </b-field>
                <b-field label="New Password" v-if="newPasswordRequired">
                    <b-input type="password" password-reveal v-model="newPassword" />
                </b-field>

                <b-button type="is-primary" @click="login">ログイン</b-button>
                <b-button type="is-primary" @click="updatePassword" v-if="newPasswordRequired">パスワード変更</b-button>
            </form>

            <b-message title="成功" type="is-success" aria-close-label="Close message" v-model="messageActive">
                パスワードの変更ができました
            </b-message>

            <b-message title="エラー" type="is-danger" aria-close-label="Close message" v-model="errorActive">
                {{ errorMessage }}
            </b-message>
        </section>
    </div>
</template>

<script>
export default {
    name: "Login",
    data: () => ({
        username: "",
        password: "",
        newPassword: "",
        newPasswordRequired: false,
        messageActive: false,
        errorMessage: "",
        errorActive: false,
    }),
    methods: {
        login: async function () {
            try {
                const result = await this.$cognito.login(this.username, this.password);
                switch (result.status) {
                    case "success":
                        this.$store.dispatch("login", result.accessToken);
                        this.$emit("close");
                        if (Object.prototype.hasOwnProperty.call(this.$route.query, 'redirect')) {
                            this.$router.push(this.$route.query.redirect);
                        } else {
                            this.$router.push({ name: 'live' });
                        }
                        break;
                    case "newPasswordRequired":
                        this.newPasswordRequired = true;
                        break;
                    default:
                        console.error("invalid status");
                        break;
                }
            } catch (e) {
                console.error(e);
                this.errorActive = true;
                this.errorMessage = "ログインできません";
            }
        },

        updatePassword: async function () {
            try {
                await this.$cognito.changePassword(this.newPassword);
                this.messageActive = true;
                this.$router.replace("/");
            } catch (err) {
                console.error(err);
                this.errorActive = true;
                this.errorMessage = err.message;
            }
        },
    },
};
</script>

<style scoped>
</style>
