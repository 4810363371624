import {
    CognitoUserPool,
    CognitoUser,
    AuthenticationDetails
} from 'amazon-cognito-identity-js'
import { Config } from 'aws-sdk'

export default class Cognito {
    configure(config) {
        if (config.userPool) {
            this.userPool = config.userPool
        } else {
            this.userPool = new CognitoUserPool({
                UserPoolId: config.UserPoolId,
                ClientId: config.ClientId
            })
        }
        Config.region = config.region
        this.options = config
    }

    static install = (Vue, options) => {
        Object.defineProperty(Vue.prototype, '$cognito', {
            get() { return this.$root._cognito }
        })

        Vue.mixin({
            beforeCreate() {
                if (this.$options.cognito) {
                    this._cognito = this.$options.cognito
                    this._cognito.configure(options)
                }
            }
        })
    }

    login(username, password) {
        const userData = { Username: username, Pool: this.userPool }
        this.cognitoUser = new CognitoUser(userData)
        return new Promise((resolve, reject) => {
            const authenticationDetails = new AuthenticationDetails({
                Username: username,
                Password: password,
            });
            this.cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: (result) => {
                    const accessToken = result.getAccessToken().getJwtToken()
                    //console.log(accessToken)
                    resolve({ status: "success", accessToken })
                },
                onFailure: (err) => {
                    reject(err)
                },
                newPasswordRequired: (userAttributes) => {
                    resolve({ status: "newPasswordRequired", userAttributes })
                }
            })
        })
    }

    changePassword(password) {
        return new Promise((resolve, reject) => {
            this.cognitoUser.completeNewPasswordChallenge(password, {}, {
                onSuccess: (result) => {
                    resolve(result)
                },
                onFailure: (err) => {
                    reject(err)
                }
            })
        })
    }

    /**
     * ログアウト
     */
    logout() {
        this.userPool.getCurrentUser().signOut()
    }

    /**
     * ログインしているかの判定
     */
    isAuthenticated() {
        const cognitoUser = this.userPool.getCurrentUser()
        return new Promise((resolve, reject) => {
            if (cognitoUser === null) { reject(cognitoUser) }
            cognitoUser.getSession((err, session) => {
                if (err) {
                    reject(err)
                } else {
                    if (!session.isValid()) {
                        reject(session)
                    } else {
                        resolve(session)
                    }
                }
            })
        })
    }
}
