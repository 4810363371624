import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

export default new Vuex.Store({
    state: {
        token: localStorage.token || "",
        lives: [],
        live: {},
        surveysAnswers: {},
        streamingSurveysAnswers: {},
        liveComments: {},
        liveReservations: {},
        liveAudienceFile: {},
        liveAggregationFile: {}
    },
    mutations: {
        token: function(state, token) {
            state.token = token;
        },
        lives: function(state, lives) {
            state.lives = lives;
        },
        live: function(state, live) {
            state.live = live;
        },
        surveysAnswers: function(state, {liveId, surveysAnswers}) {
            state.surveysAnswers[liveId] = surveysAnswers;
        },
        streamingSurveysAnswers: function(state, {liveId, streamingSurveysAnswers}) {
            state.streamingSurveysAnswers[liveId] = streamingSurveysAnswers;
        },
        liveComments: function(state, {liveId, liveComments}) {
            state.liveComments[liveId] = liveComments;
        },
        liveReservations: function(state, {liveId, liveReservations}) {
            state.liveReservations[liveId] = liveReservations;
        },
        // @TODO: filesとかにliveAggregationFileとまとめる
        liveAudienceFile: function(state, {liveId, liveAudienceFile}) {
            state.liveAudienceFile[liveId] = liveAudienceFile;
        },
        liveAggregationFile: function(state, {liveId, liveAggregationFile}) {
            state.liveAggregationFile[liveId] = liveAggregationFile;
        }
    },
    getters: {
        loggedOut: state => state.token === "",
        lives: state => state.lives,
        live: state => () => state.live || {},
        surveysAnswers: state => liveId => state.surveysAnswers[liveId] || [],
        streamingSurveysAnswers: state => liveId => state.streamingSurveysAnswers[liveId] || [],
        liveComments: state => liveId => state.liveComments[liveId] || [],
        liveReservations: state => liveId => state.liveReservations[liveId] || [],
        liveAudienceFile: state => liveId => state.liveAudienceFile[liveId] || [],
        liveAggregationFile: state => liveId => state.liveAggregationFile[liveId] || []
    },
    actions: {
        logout: function({commit}) {
            localStorage.removeItem("token");
            delete axios.defaults.headers.common["Authorization"];
            commit("token", "");
        },
        login: function({commit}, token) {
            localStorage.token = token;
            axios.defaults.headers.common["Authorization"] = token;
            commit("token", token);
        },
        getLives: async function({commit}) {
            const response = await axios.get("/lives");
            const lives = response.data;
            commit("lives", lives);
        },
        getLive: async function({commit}, liveId) {
            if (!liveId) {
                console.error("[getLive]parameter not found");
                return;
            }

            const response = await axios.get(`/lives/${liveId}`, {});

            const live = response.data;
            commit("live", live);
        },
        getSurveysAnswers: async function({commit}, liveId) {
            if (!liveId) {
                console.error("[getSurveysAnswers]parameter not found");
                return;
            }

            const response = await axios.get(`/lives/${liveId}/audience-surveys`, {});

            const surveysAnswers = response.data;
            commit("surveysAnswers", {liveId, surveysAnswers});
        },
        getStreamingSurveysAnswers: async function({commit}, liveId) {
            if (!liveId) {
                console.error("[getStreamingSurveysAnswers]parameter not found");
                return;
            }

            const response = await axios.get(`/lives/${liveId}/streaming-surveys`, {});

            const streamingSurveysAnswers = response.data;
            commit("streamingSurveysAnswers", {liveId, streamingSurveysAnswers});
        },
        getLiveComments: async function({commit}, liveId) {
            if (!liveId) {
                console.error("[getLiveComments]parameter not found");
                return;
            }

            const response = await axios.get(`/lives/${liveId}/live-comments`, {});

            const liveComments = response.data;
            commit("liveComments", {liveId, liveComments});
        },
        getLiveReservations: async function({commit}, liveId) {
            if (!liveId) {
                console.error("[getLiveReservations]parameter not found");
                return;
            }
            try {
                const response = await axios.get(`/lives/${liveId}/live-reservations`, {});

                const liveReservations = response.data;
                commit("liveReservations", {liveId, liveReservations});
            } catch (err) {
                console.error(err);
                return err;
            }
        },
        getLiveAudienceFile: async function({commit}, {liveId, fileName}) {
            if (!liveId) {
                console.error("[getLiveAudienceFile]parameter not found");
                return;
            }
            try {
                const response = await axios.get(
                    `https://fhonnmb106.execute-api.ap-northeast-1.amazonaws.com/lives/${liveId}/audience-file`,
                    {params: {fileName}}
                );
                console.log(response);
                const liveAudienceFile = response.data;
                commit("liveAudienceFile", {liveId, liveAudienceFile});
            } catch (err) {
                console.error(err);
            }
        },
        getLiveAggregationFile: async function({commit}, {liveId, fileName}) {
            if (!liveId) {
                console.error("[getLiveAggregationFile]parameter not found");
                return;
            }
            try {
                const response = await axios.get(
                    `https://fhonnmb106.execute-api.ap-northeast-1.amazonaws.com/lives/${liveId}/aggregation-file`,
                    {params: {fileName}}
                );

                const liveAggregationFile = response.data;
                commit("liveAggregationFile", {liveId, liveAggregationFile});
            } catch (err) {
                console.error(err);
            }
        },
        downloadLiveAudienceFile: async function({commit}, {liveId, fileName}) {
            if (!liveId) {
                console.error("[downloadLiveAudienceFile]parameter not found");
                return;
            }
            try {
                const response = await axios.post(
                    `https://fhonnmb106.execute-api.ap-northeast-1.amazonaws.com/lives/${liveId}/audience-file`,
                    {},
                    {
                        params: {fileName},
                        headers: {"Content-Type": "application/json"}
                    }
                );

                const liveAudienceFile = response.data;
                commit("liveAudienceFile", {liveId, liveAudienceFile});
            } catch (err) {
                console.error(err);
            }
        },
        downloadLiveAggregationFile: async function({commit}, {liveId, fileName}) {
            if (!liveId) {
                console.error("[downloadLiveAggregationFile]parameter not found");
                return;
            }
            try {
                const response = await axios.post(
                    `https://fhonnmb106.execute-api.ap-northeast-1.amazonaws.com/lives/${liveId}/aggregation-file`,
                    {},
                    {
                        params: {fileName},
                        headers: {"Content-Type": "application/json"}
                    }
                );

                const liveAggregationFile = response.data;
                commit("liveAggregationFile", {liveId, liveAggregationFile});
            } catch (err) {
                console.error(err);
            }
        }
    }
});
