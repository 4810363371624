<template>
    <div class="container">
        <h1 class="title">視聴予約者 -- {{ live.EventName ? live.EventName + live.Title : live.Title }}</h1>
        <h2 class="subtitle">({{ live.UUID }})</h2>

        <b-loading is-full-page v-model="isLoading"></b-loading>
        <div v-if="!isLoading">
            <div class="buttons">
                <b-button @click="reload">Reload</b-button>

                <b-dropdown aria-role="list">
                    <template #trigger>
                        <b-button label="ダウンロード" type="is-success" icon-right="angle-down" />
                    </template>
                    <b-dropdown-item aria-role="listitem" key="download">
                        <b-icon icon="download"></b-icon>
                        <vue-excel-xlsx
                            :data="liveReservations"
                            :columns="columns"
                            :filename="`視聴予約者リスト_${live.Title}_${formatDateYMD(localTime())}`"
                            sheetname="視聴予約者リスト"
                        >
                            視聴予約者リスト
                        </vue-excel-xlsx>
                    </b-dropdown-item>
                </b-dropdown>
            </div>
            <b-table :data="liveReservations" striped hoverable narrowed>
                <b-table-column field="id" label="ID" v-slot="props" cell-class="t-head-width">
                    {{ props.row.Id }}
                </b-table-column>
                <b-table-column field="Email" label="email" v-slot="props" cell-class="t-head-width">
                    {{ props.row.Email }}
                </b-table-column>
                <b-table-column field="Name" label="氏名" v-slot="props" cell-class="t-head-width">
                    {{ props.row.Name }}
                </b-table-column>
                <b-table-column field="Hospital" label="所属施設" v-slot="props" cell-class="t-head-width">
                    {{ props.row.Hospital }}
                </b-table-column>
                <b-table-column field="Prefecture" label="所属施設都道府県" v-slot="props" cell-class="t-head-width">
                    {{ props.row.Prefecture }}
                </b-table-column>
                <b-table-column field="Country" label="所属施設国" v-slot="props" cell-class="t-head-width">
                    {{ props.row.Country }}
                </b-table-column>
                <b-table-column field="Speciality" label="専門科" v-slot="props" cell-class="t-head-width">
                    {{ props.row.Speciality }}
                </b-table-column>
                <b-table-column field="UserType" label="区分" v-slot="props" cell-class="t-head-width">
                    {{ props.row.UserType }}
                </b-table-column>
                <b-table-column field="Birth" label="年齢" v-slot="props" cell-class="t-head-width">
                    {{ props.row.Birth }}
                </b-table-column>
                <b-table-column field="Created" label="予約日時" v-slot="props" cell-class="t-head-width">
                    {{ props.row.Created | formatDate }}
                </b-table-column>
            </b-table>
        </div>

        <b-message
            auto-close
            title="Error!"
            type="is-danger"
            has-icon
            v-model="errorActive"
            aria-close-label="Close message"
        >
            エラーが発生しました
        </b-message>
    </div>
</template>

<script>
import formatterMixin from "../mixins/formatter";

export default {
    name: "Reservation",
    mixins: [formatterMixin],
    data: () => ({
        isLoading: true,
        errorActive: false,
        columns: [
            {
                label: "ID",
                field: "Id",
            },
            {
                label: "email",
                field: "Email",
            },
            {
                label: "氏名",
                field: "Name",
            },
            {
                label: "所属施設",
                field: "Hospital",
            },
            {
                label: "所属施設都道府県",
                field: "Prefecture",
            },
            {
                label: "所属施設国",
                field: "Country",
            },
            {
                label: "専門科",
                field: "Speciality",
            },
            {
                label: "区分",
                field: "UserType",
            },
            {
                label: "年齢",
                field: "Birth",
            },
            {
                label: "予約日時",
                field: "Created",
            },
        ],
    }),
    async created() {
        this.isLoading = true;
        await this.$store.dispatch("getLive", this.$route.params.liveId);
        await this.$store.dispatch("getLiveReservations", this.$route.params.liveId);
        this.isLoading = false;
    },
    watch: {
        $route: "reload",
    },
    computed: {
        liveReservations() {
            let reserve = this.$store.getters.liveReservations(this.$route.params.liveId);
            reserve.map((user) => {
                user.Created = user.Created.replace("T", " ").replace("Z", "");
                user.UserCreated = user.UserCreated.replace("T", " ").replace("Z", "");
                return user;
            });
            return reserve;
        },
        live() {
            return this.$store.getters.live();
        },
    },
    methods: {
        async reload() {
            this.isLoading = true;
            await this.$store.dispatch("getLive", this.$route.params.liveId);
            const err = await this.$store.dispatch("getLiveReservations", this.$route.params.liveId);
            if (err) {
                this.errorActive = true;
            }
            this.isLoading = false;
        },
        localTime() {
            const tzoffset = new Date().getTimezoneOffset() * 60000;
            return new Date(Date.now() - tzoffset).toISOString();
        },
    },
};
</script>

<style scoped>
div >>> .t-head-width {
    min-width: 100px;
}
</style>
