<template>
    <div id="app">
        <b-navbar type="is-black">
            <template #brand>
                <b-navbar-item tag="router-link" :to="{ name: 'live' }">
                    <img src="" />
                </b-navbar-item>
            </template>
            <template #start>
                <b-navbar-item tag="router-link" :to="{ name: 'live' }"> Live一覧 </b-navbar-item>
            </template>

            <template #end>
                <b-navbar-item tag="div">
                    <div class="buttons">
                        <b-button class="button is-light" @click="logout" v-if="!loggedOut"> Logout </b-button>
                    </div>
                </b-navbar-item>
            </template>
        </b-navbar>

        <b-modal v-model="loggedOut" has-modal-card trap-focus aria-role="dialog" aria-label="Example Modal" aria-modal>
            <Login @close="showLoginModal = false"></Login>
        </b-modal>

        <router-view></router-view>
    </div>
</template>

<script>
import Login from "./components/Login";

export default {
    name: "App",
    data: () => ({
        showLoginModal: false,
    }),
    components: { Login },
    created: function () {
        this.$http.interceptors.response.use(undefined, async (err) => {
            if (err.response.status === 401) {
                await this.$store.dispatch("logout");
                return
            }
            console.log(err)
            throw err;
        });
    },
    computed: {
        loggedOut: {
            get() {
                return this.$store.getters.loggedOut;
            },
        },
    },
    methods: {
        logout() {
            this.$store.dispatch("logout");
            this.$router.push({ name: 'home' });
        },
    },
};
</script>
