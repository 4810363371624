<template>
    <div class="container">
        <h1 class="title">ライブ中アンケート -- {{ live.Title }}</h1>
        <h2 class="subtitle">({{ live.UUID }})</h2>

        <p class="update-msg">
            [Update 2022/11/03]
            「視聴有無」列を追加しました。
        </p>

        <b-loading is-full-page v-model="isLoading"></b-loading>
        <div v-if="!isLoading">
            <div class="buttons">
                <b-button @click="reload">Reload</b-button>

                <b-dropdown aria-role="list">
                    <template #trigger>
                        <b-button label="ダウンロード" type="is-success" icon-right="angle-down" />
                    </template>
                    <b-dropdown-item aria-role="listitem" key="download">
                        <b-icon icon="download"></b-icon>
                        <vue-excel-xlsx
                            :data="surveyAnswers"
                            :columns="columns"
                            :filename="`ライブ中アンケート_${live.Title}_${formatDateYMD(live.ScheduledStartDateTime)}`"
                            sheetname="ライブ中アンケート"
                        >
                            ライブ中アンケート
                        </vue-excel-xlsx>
                    </b-dropdown-item>
                </b-dropdown>
            </div>

            <b-table :data="surveyAnswers" striped hoverable narrowed>
                <b-table-column field="Id" label="ユーザID" numeric v-slot="props">
                    {{ props.row.Id }}
                </b-table-column>
                <b-table-column field="Name" label="氏名" v-slot="props">
                    {{ props.row.Name }}
                </b-table-column>
                <b-table-column field="Hospital" label="所属施設" v-slot="props">
                    {{ props.row.Hospital }}
                </b-table-column>
                <b-table-column field="Prefecture" label="都道府県" v-slot="props">
                    {{ props.row.Prefecture }}
                </b-table-column>
                <b-table-column field="QuestionId" label="質問ID" numeric v-slot="props">
                    {{ props.row.QuestionId }}
                </b-table-column>
                <b-table-column field="QuestionContent" label="質問内容" v-slot="props">
                    {{ props.row.Content }}
                </b-table-column>
                <b-table-column field="AnswerId" label="回答ID" numeric v-slot="props">
                    {{ props.row.ChoiceId }}
                </b-table-column>
                <b-table-column field="AnswerContent" label="回答内容" v-slot="props">
                    {{ props.row.ChoiceContent }}
                </b-table-column>
                <b-table-column field="AnswerCreated" label="回答日時" v-slot="props">
                    {{ props.row.Created | formatDate }}
                </b-table-column>
                <b-table-column field="Watched" label="視聴有無" v-slot="props">
                    {{ props.row.Watched }}
                </b-table-column>
            </b-table>
        </div>
    </div>
</template>

<script>
import formatterMixin from "../mixins/formatter";

export default {
    name: "StreamingSurvey",
    mixins: [formatterMixin],
    data: () => ({
        activeTab: 0,
        isLoading: true,
        columns: [
            {
                label: "ユーザID",
                field: "Id",
            },
            {
                label: "氏名",
                field: "Name",
            },
            {
                label: "所属施設",
                field: "Hospital",
            },
            {
                label: "都道府県",
                field: "Prefecture",
            },
            {
                label: "質問ID",
                field: "QuestionId",
            },
            {
                label: "質問内容",
                field: "Content",
            },
            {
                label: "回答ID",
                field: "ChoiceId",
            },
            {
                label: "回答内容",
                field: "ChoiceContent",
            },
            {
                label: "回答日時",
                field: "Created",
            },
            {
                label: "視聴有無",
                field: "Watched",
            },
        ],
    }),
    async created() {
        this.isLoading = true;
        await this.$store.dispatch("getLive", this.$route.params.liveId);
        await this.$store.dispatch("getStreamingSurveysAnswers", this.$route.params.liveId);
        this.isLoading = false;
    },
    watch: {
        $route: "reload",
    },
    computed: {
        surveyAnswers() {
            let surveyAnswers = this.$store.getters.streamingSurveysAnswers(this.$route.params.liveId);
            return surveyAnswers.map((answer) => {
                answer.Created = answer.Created.replace("T", " ").replace("Z", "");
                return answer;
            });
        },
        live() {
            return this.$store.getters.live();
        },
    },
    methods: {
        async reload() {
            this.isLoading = true;
            await this.$store.dispatch("getLive", this.$route.params.liveId);
            await this.$store.dispatch("getStreamingSurveysAnswers", this.$route.params.liveId);
            this.isLoading = false;
        },
    },
};
</script>

<style scoped>
</style>
