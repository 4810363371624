<template>
    <div class="container">
        <h1 class="title">視聴者データ -- {{ live.EventName ? live.EventName + live.Title : live.Title }}</h1>

        <b-loading is-full-page v-model="isLoading"></b-loading>
        <div v-if="!isLoading">
            <div class="buttons">
                <b-button @click="reload">Reload</b-button>
            </div>
            <div v-if="!showDownloadButtons()">ライブ翌日AM７時よりダウンロード可能です</div>
            <div v-if="showDownloadButtons()">
                <div class="buttons">
                    <label for="">視聴者リスト</label>
                    <b-button
                        v-if="!isGeneratingAudienceFile && !audienceFile.url && !audienceFile.generatingFile"
                        @click="postAudienceFile"
                        >集計開始</b-button
                    >
                    <b-button v-if="isGeneratingAudienceFile || audienceFile.generatingFile" disabled>集計中</b-button>
                    <a v-if="audienceFile.url" :href="audienceFile.url">ダウンロード</a>
                </div>
                <div class="buttons">
                    <label for="">集計リスト(社内用)</label>
                    <b-button
                        v-if="!isGeneratingAggregationFile && !aggregationFile.url && !aggregationFile.generatingFile"
                        @click="postAggregationFile"
                        >集計開始（3~5分ほどかかります）</b-button
                    >
                    <b-button v-if="isGeneratingAggregationFile || aggregationFile.generatingFile" disabled
                        >集計中</b-button
                    >
                    <a v-if="aggregationFile.url" :href="aggregationFile.url">ダウンロード</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import formatterMixin from "../mixins/formatter";

export default {
    name: "AudienceData",
    mixins: [formatterMixin],
    data: () => ({
        isLoading: true,
        isGeneratingAudienceFile: false,
        isGeneratingAggregationFile: false,
    }),
    async created() {
        await this.reload();
    },
    watch: {
        $route: "reload",
    },
    computed: {
        live() {
            return this.$store.getters.live();
        },
        audienceFile() {
            return this.$store.getters.liveAudienceFile(this.$route.params.liveId);
        },
        aggregationFile() {
            return this.$store.getters.liveAggregationFile(this.$route.params.liveId);
        },
    },
    methods: {
        async reload() {
            this.isLoading = true;
            await this.$store.dispatch("getLive", this.$route.params.liveId);

            const live = this.$store.getters.live();

            const audienceFileName = `視聴者リスト_${live.Title}_${this.formatDateYMD(
                live.ScheduledStartDateTime
            )}.xlsx`;

            await this.$store.dispatch("getLiveAudienceFile", {
                liveId: this.$route.params.liveId,
                fileName: audienceFileName,
            });

            const aggregationFileName = `集計リスト（社内用）_${live.Title}_${this.formatDateYMD(
                live.ScheduledStartDateTime
            )}.xlsx`;

            await this.$store.dispatch("getLiveAggregationFile", {
                liveId: this.$route.params.liveId,
                fileName: aggregationFileName,
            });

            this.isLoading = false;
        },
        async postAudienceFile() {
            this.isGeneratingAudienceFile = true;

            const live = this.$store.getters.live();

            const fileName = `視聴者リスト_${live.Title}_${this.formatDateYMD(live.ScheduledStartDateTime)}.xlsx`;

            await this.$store.dispatch("downloadLiveAudienceFile", {liveId: this.$route.params.liveId, fileName});
        },
        async postAggregationFile() {
            this.isGeneratingAggregationFile = true;

            const live = this.$store.getters.live();

            const fileName = `集計リスト（社内用）_${live.Title}_${this.formatDateYMD(
                live.ScheduledStartDateTime
            )}.xlsx`;

            await this.$store.dispatch("downloadLiveAggregationFile", {
                liveId: this.$route.params.liveId,
                fileName,
            });
        },
        showDownloadButtons() {
            if (!this.live.ActualEndDateTime) {
                console.log("not");
                return false;
            }
            // @TODO: 要リファクタ
            let liveEndDate = new Date(this.live.ActualEndDateTime).setHours(-9);
            let endDay = new Date(liveEndDate);
            let tmp = new Date(endDay.getFullYear(), endDay.getMonth(), endDay.getDate());
            let nextDay = tmp.setDate(endDay.getDate() + 1);
            // 翌日のAM7時より公開。集計はecsで６時から
            let openingDate = new Date(nextDay).setHours(7);
            return new Date() > new Date(openingDate);
        },
    },
};
</script>

<style scoped>
</style>
