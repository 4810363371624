import Vue from 'vue'
import VueRouter from 'vue-router'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import Axios from 'axios'

import config from '@/config'
import Cognito from '@/cognito/Cognito'

import App from '@/App.vue'
import store from '@/Store'
import Live from '@/components/Live.vue'
import Survey from '@/components/Survey.vue'
import StreamingSurvey from '@/components/StreamingSurvey.vue'
import Comment from '@/components/Comment.vue'
import Reservation from '@/components/Reservation.vue'
import AudienceData from '@/components/AudienceData.vue'

import VueExcelXlsx from "vue-excel-xlsx";

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCheck, faCheckCircle, faInfoCircle, faExclamationTriangle, faExclamationCircle,
  faArrowUp, faAngleRight, faAngleLeft, faAngleDown, faDownload,
  faEye, faEyeSlash, faCaretDown, faCaretUp, faUpload, faLink
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faCheck, faCheckCircle, faInfoCircle, faExclamationTriangle, faExclamationCircle,
  faArrowUp, faAngleRight, faAngleLeft, faAngleDown, faLink, faDownload,
  faEye, faEyeSlash, faCaretDown, faCaretUp, faUpload);

Vue.component('vue-fontawesome', FontAwesomeIcon);

Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(Buefy, {
  defaultIconComponent: 'vue-fontawesome',
  defaultIconPack: 'fas',
})
Vue.use(Cognito, config)
Vue.use(VueExcelXlsx);

Vue.prototype.$http = Axios;
const token = localStorage.getItem('token')
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = token
}

 Vue.filter('formatDate', function (value) {
      if (!value) return ''
      return value.replace('T', ' ').replace('Z', '');
})

const routes = [
  { path: '/', name: 'home' },
  { path: '/lives', component: Live, name: 'live', meta: { requiresAuth: true } },
  { path: '/lives/:liveId/audience-surveys', component: Survey, name: 'audienceSurvey', meta: { requiresAuth: true } },
  { path: '/lives/:liveId/streaming-surveys', component: StreamingSurvey, name: 'streamingSurvey', meta: { requiresAuth: true } },
  { path: '/lives/:liveId/comments', component: Comment, name: 'comment', meta: { requiresAuth: true } },
  { path: '/lives/:liveId/reservaitons', component: Reservation, name: 'reservation', meta: { requiresAuth: true } },
  { path: '/lives/:liveId/audience-data', component: AudienceData, name: 'audienceData', meta: { requiresAuth: true } },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth) && !store.state.token) {
    next({ path: '/', query: { redirect: to.fullPath } });
  } else if(to.name === 'home' && store.state.token) {
    next({ name: 'live' })
  } else {
    next();
  }
});

const cognito = new Cognito()

new Vue({
  render: h => h(App),
  router,
  cognito,
  store,
}).$mount('#app')
