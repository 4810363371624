<template>
    <div class="container">
        <h1 class="title">コメント -- {{ live.EventName ? live.EventName + live.Title : live.Title }}</h1>
        <h2 class="subtitle">({{ live.UUID }})</h2>

        <b-loading is-full-page v-model="isLoading"></b-loading>
        <div v-if="!isLoading">
            <div class="buttons">
                <b-button @click="reload">Reload</b-button>

                <b-dropdown aria-role="list">
                    <template #trigger>
                        <b-button label="ダウンロード" type="is-success" icon-right="angle-down" />
                    </template>
                    <b-dropdown-item aria-role="listitem" key="download">
                        <b-icon icon="download"></b-icon>
                        <vue-excel-xlsx
                            :data="liveComments"
                            :columns="columns"
                            :filename="`コメント_${live.Title}_${formatDateYMD(live.ScheduledStartDateTime)}`"
                            sheetname="コメント"
                        >
                            コメント
                        </vue-excel-xlsx>
                    </b-dropdown-item>
                </b-dropdown>
            </div>

            <b-table :data="liveComments" striped hoverable narrowed>
                <b-table-column field="Name" label="氏名" v-slot="props">
                    {{ props.row.Name }}
                </b-table-column>
                <b-table-column field="Comment" label="コメント" v-slot="props">
                    {{ props.row.Comment }}
                </b-table-column>
                <b-table-column field="Created" label="投稿日時" v-slot="props">
                    {{ props.row.Created | formatDate }}
                </b-table-column>
                <b-table-column field="HiddenDate" label="取消日時" numeric v-slot="props">
                    {{ props.row.HiddenDate | formatDate }}
                </b-table-column>
            </b-table>
        </div>
    </div>
</template>

<script>
import formatterMixin from "../mixins/formatter";

export default {
    name: "Comment",
    mixins: [formatterMixin],
    data: () => ({
        activeTab: 0,
        isLoading: true,
        columns: [
            {
                label: "氏名",
                field: "Name",
            },
            {
                label: "コメント",
                field: "Comment",
            },
            {
                label: "投稿日",
                field: "Created",
            },
            {
                label: "取消日時",
                field: "HiddenDate",
            },
        ],
    }),
    async created() {
        this.isLoading = true;
        await this.$store.dispatch("getLive", this.$route.params.liveId);
        await this.$store.dispatch("getLiveComments", this.$route.params.liveId);
        this.isLoading = false;
    },
    watch: {
        $route: "reload",
    },
    computed: {
        liveComments() {
            let comments = this.$store.getters.liveComments(this.$route.params.liveId);
            comments.map((comment) => {
                comment.Created = comment.Created.replace("T", " ").replace("Z", "");
                if (comment.HiddenDate) {
                    comment.HiddenDate = comment.HiddenDate.replace("T", " ").replace("Z", "");
                }
                return comment;
            });
            return comments;
        },
        live() {
            return this.$store.getters.live();
        },
    },
    methods: {
        async reload() {
            this.isLoading = true;
            await this.$store.dispatch("getLive", this.$route.params.liveId);
            await this.$store.dispatch("getLiveComments", this.$route.params.liveId);
            this.isLoading = false;
        },
    },
};
</script>

<style scoped>
</style>
