export default {
    methods: {
        formatDateYMD(date) {
            if (!date) {
                return date;
            }

            const i = date.search("T");

            if (i === -1) return date;

            return date.substring(0, i).replaceAll("-", "");
        }
    }
};
